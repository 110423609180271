@use "../../styles/partials/variables" as *;
@use "../../styles/partials/breakpoints" as *;

.footer{
    background-color: $Secondary-color;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
    height: auto;
    
    @include desktop{
        justify-content: center;
    }

   
}