@use "../../styles/partials/variables" as *;

.main{
    padding: 1rem;
    text-align: center;
    font-size: 24px;
    &__heading{
        color: $Secondary-color;
        
    }
    &__italic{
        font-style: italic;
    }
}