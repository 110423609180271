//Media Screen Sizes
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;


$Main-color:blue;
$Secondary-color:#2292df;
$Black:black;
$White:white

