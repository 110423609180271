@use "../../styles/partials/variables" as *;
@use "../../styles/partials/breakpoints" as *;

.last{
    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    //margin: 0px auto;
    justify-content: space-between;
    background-color: $Secondary-color;

    
        &__left{
            margin-left: 2rem;
            img{
                height: 250px;
            }
            @include desktop{

            margin-left: 15rem;
            img{
                height: 200px;
            }
           }
    }
  
   &__middle{
    //margin-right: 20rem;
   }
   &__right{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-right: 15rem;
    
    img{
        height: 50px;
    }
   }
}





// .lastR {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 100%; // Adjust the width as needed
//     margin: 20px auto; // Center the component on the page
  
//     .row {
//       display: flex;
//       flex-direction: row;
//       justify-content: space-between;
//       align-items: center;
//       width: 100%;
//     }
  
//     .left-content {
//       img {
//         max-width: 20%;
//         height: 300px;
//       }
//     }
  
//     .center-content {
//       text-align: center;
      
//     }
  
//     .right-content {
//       .social-icons {
//         display: flex;
  
//         img {
//           width: 50px; // Adjust the width of the social icons as needed
//           height: auto;
//           margin-left: 10px; // Add spacing between the icons
//         }
//       }
//     }
//   }
  