@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;


.about {
  padding: 1rem;
  //margin: 1rem;
  &__heading {
    margin-top: 1rem;
    //margin-bottom: 1rem;
    color: Blue;
  }
 
}
.carousel{
  @include desktop{
    height: 700px;

  }
    height: 300px;
    object-fit:cover;
    background-repeat: no-repeat;
    object-position: center;
}
// .carousel-text {
//   color: white;
//   font-size: 35px;
//   font-weight: bold;
//   @include desktop {
//     font-size: 45px;
//     font-weight: bold;
//     color: white;
//   }
// }
