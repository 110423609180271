.products{
    padding: 1rem;
    img{
        height: 500px;
        object-fit: cover;
        max-width: 100%;
        margin-top: 2rem;
    
    }

    &__contents--heading{
        margin-top: -8rem;
        text-align: center;
    }
}