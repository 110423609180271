@use "../../styles/partials/variables" as *;
@use "../../styles/partials/breakpoints" as *;

.carousel-img{
    height: 700px;
    object-fit:cover;
    
}
.caption{
    margin-bottom: 15rem;
    color:$Secondary-color;

    
    &__hThree{
        font-style: italic;
    }
}

