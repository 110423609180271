
.directors-container {
    text-align: center;
    padding: 20px;
    background-color: lightblue;
  
    .directors {
      display: flex;
      justify-content: space-around;
  
      .director {
        margin: 20px;
  
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
        }
  
        .director-info {
          margin-top: 10px;
        }
      }
    }
  }
  
  .heading{
    
    &__two{
        color: green;
    }
  }