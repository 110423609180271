@use "../../styles/partials/breakpoints" as *;
@use "../../styles/partials/variables" as *;


.navbar {
    height:auto;
    @include tablet{
  height: 100px;
    }
}
.menu {
  border: 1px solid green;
  
}
.nav-link {
  border: 1px solid #333; /* Add a border to the nav links */
  padding: 3px 15px; /* Adjust padding as needed */
  border-radius: 4px; /* Add rounded corners */
  transition: background-color 0.3s; /* Smooth transition for the background color */
  color: blue; /* Text color */
  text-decoration: none;
}

.nav-link:hover {
  background-color:blue; /* Change the background color on hover */
  color: #fff; /* Text color on hover */
  border-color: green; /* Change the border color on hover */
}
.logo {
    height: 150px;
    //margin-bottom: -2rem;
    //margin-top: -1rem;
  @include desktop{
    height: 100px;
    
  }
}

