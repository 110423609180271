.gallery {
    text-align: center;
    width: 80%; // Adjust the width as needed
    margin: 20px auto; // Center the component on the page
  
    h2 {
      color: #333; // Set the title color
    }
  
    .image-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 30px; // Adjust the gap between images
  
      img {
        max-width: 100%;
        height: auto;
        border-radius: 10px; // Add border-radius for a rounded look
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Add a subtle box-shadow for a lift
        transition: transform 0.3s ease-in-out;
  
        &:hover {
          transform: scale(1.1); // Increase the size on hover
        }
      }
    }
  }
  