/* src/Team.css */
.team-container {
    text-align: center;
    padding: 20px;
    background-color: lightblue;
  }
  
  .team-members {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .team-member {
    margin: 20px;
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .member-info {
    margin-top: 10px;
  }
  
  .heading{
    color: black;
    &__two{
        color: green;
    }
  }