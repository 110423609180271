@use "../../styles/partials/variables" as *;
@use "../../styles/partials/breakpoints" as *;


.card {
  border: 1px solid #ccc;
  width: 80%; // Adjust the width as needed
  margin: 20px auto; // Center the card on the page

  .row {
    @include desktop{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
   display: block;
  }

  .left-content {
    padding: 20px;
    flex: 1;

    h2 {
      color: $Secondary-color;
    }

    p {
      margin-top: 10px;
      color: $Black;
      font-size: larger;
    }

    .learn-more-button {
      margin-top: 10px; // Add space between description and button
      padding: 10px;
      background-color: #007bff; // Set the button background color to blue
      color: white; // Set the button text color to white
      border: none;
      cursor: pointer;
    }
  }

  .right-content {
    padding: 20px;
    flex: 1;

    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}

// .card {
//   border: 1px solid #ccc;
//   width: 80%; // Adjust the width as needed
//   margin: 20px auto; // Center the card on the page
  
  

//   .row {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .left-content {
//     padding: 20px;
//     flex: 1;

//     h2{
//       color: $Secondary-color;
//     }
//     p{
//       margin-top: 10px;
//       color: $Black;
//       font-size:larger;
//     }
//     .learn-more-button {
//       margin-top: 10px; // Add space between description and button
//       padding: 10px;
//       background-color: #007bff; // Set the button background color to blue
//       color: white; // Set the button text color to white
//       border: none;
//       cursor: pointer;
//     }
//   }

//   .right-content {
//     padding: 20px;
//     flex: 1;

//     img {
//       max-width: 100%;
//       height: auto;
//       object-fit: cover;
//     }
//   }
// }
